<template>
    <footer class="app-footer" v-if="region">
        <div class="social-block">
            Присоединяйтесь к нам в социальных сетях
            <div class="social-icons" v-once>
                <a v-for="icon of socialIcons" 
                    target="_blank"
                    rel="nofollow" 
                    :key="icon.href"
                    :href="icon.href">
                    <img :src="icon.icon">
                </a>
            </div>
        </div>
        <div class="full-version-button" @click="switchToBigTriya()">
            Полная версия сайта
        </div>
        <div class="copyright">
            &laquo;ТриЯ&raquo;, 2024 &copy;,
            <a :href="'tel:' + phones">телефон: {{phones}}</a>
        </div>
    </footer>
    <client-only>
        <footer-bottom-flash></footer-bottom-flash>
    </client-only>
</template>

<script lang="ts" setup>
import { useMainStore } from '~~/store/mainState';

import cookies from 'js-cookie';

const socialIcons = [{
    href: 'https://vk.com/triyaru',
    icon: '/images/social/soc_VK.svg',
}, {
    href: 'https://www.odnoklassniki.ru/triyaru',
    icon: '/images/social/soc_OK.svg',
}];

const region = useMainStore().region;


const phones = computed(() => region?.phones[0])

function switchToBigTriya() {

    cookies.set('device', 'desktop', {
        domain: '.triya.ru',
        expires: 90,
    });

    window.location.href = 'https://www.triya.ru' + useRoute().fullPath;
}

</script>

<style lang="scss" scoped>

@import '~/assets/css/colors.scss';
footer.app-footer{

text-align: center;

padding-top: 5px;

&>.social-block{
    &>.social-icons{
        color: $color-grey-middle;
        &>*{
            padding: 0 3px;
        }
    }
}

&>.full-version-button{
    color: $color-grey-dark;
    padding: 0 5px 5px;
    font-size: 15px;
    text-transform: capitalize;
    user-select: none;
    cursor: pointer;
}

&>.copyright{
    font-weight: 500;
    font-size: 12px;
    color: $color-grey-middle;

    a {
        color: inherit;
        text-decoration: none;
    }
    
}
}

</style>