<template>
    <template v-if="flashStore.bottomFlash">
        <div class="container" @click="flashStore.closeBottomFlash()">
            <div class="timeout" v-if="flashStore.bottomFlash.timeout" :style="{'--s': width}"></div>
            <div class="header">{{ flashStore.bottomFlash.message }}</div>
            <div class="sub" v-if="flashStore.bottomFlash.sub">{{ flashStore.bottomFlash.sub }}</div>
        </div>
    </template>

</template>

<script setup lang="ts">
import anime from 'animejs';
import { useFlashStore } from '~/store/flashStore';

const flashStore = useFlashStore();



const width = ref('0%');

let animation: anime.AnimeInstance | null = null;

function stopAnimation() {
    if (animation) {
        animation.pause();
        animation = null;
    }
}

function setupTimeout() {
    animation = anime({
        targets: width,
        value: ['100%', '0%'],
        easing: 'linear',
        duration: flashStore.bottomFlash!.timeout!,
        complete: () => {
            flashStore.closeBottomFlash();
        }
    });
}

watch(() => flashStore.bottomFlash, () => {
    stopAnimation();

    if (flashStore.bottomFlash?.timeout) {
        setupTimeout();
    }
})

</script>

<style lang="scss" scoped>

.container {
    position: fixed;
    bottom: 0;
    margin: 14px;
    z-index: 1009;
    border-radius: 4px;
    padding: 0 7px 7px;
    width: calc(100% - 28px);
    background-color: var(--bg0);
    border: 1px solid var(--brdr3);
    overflow: hidden;
}

.timeout {
    position: absolute;
    left: 0;
    top: 0;
    width: var(--s);
    height: 4px;
    background-color: var(--acc);
}

.header {
    padding-top: 7px;
    color: var(--t0);
}
.sub {
    padding-top: 7px;
    color: var(--t3);
}

</style>