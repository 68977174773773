import { defineStore } from "pinia";
import { useApi } from "~~/lib";
import type { IOffer, IOfferList } from "~~/types/catalog";

export const useCartSuggestedOffersStore = defineStore('cart-suggested-offers', () => {
    const offers: Ref<IOffer[] | null> = ref(null);

    const api = useApi();

    let valid = ref(false);

    function checkOffers() {
        if (!offers.value || !valid.value) {
            refresh();
        }
    }

    function invalidate() {
        valid.value = false;
    }

    async function refresh() {
        offers.value = await api.request('/rest/cart/suggested-offers');
        valid.value = true;
    }

    const list: Ref<IOfferList | null> = computed(() => {

        if (!offers.value?.length) {
            return null;
        }

        return {
            id: 'suggested-offers',
            offers: offers.value,
            name: 'С этими товарами покупают'
        }
    })

    return { list, checkOffers, refresh, invalidate, valid };
});
