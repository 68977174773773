import { defineStore } from "pinia";

export const useDialogStore = defineStore('dialogs', () => {
    const dialogs = shallowReactive(new Set<string>());

    // function open(code: string) {
    //     dialogs.add(code);
    // }

    // function close(code: string) {
    //     dialogs.delete(code);
    // }

    function set(code: string, active: boolean) {
        active ? dialogs.add(code) : dialogs.delete(code);
    }

    const active = computed(() => !!dialogs.size);

    return { /*open, close,*/ set, active };
});
