<template>
    <svg class="svg-icon" view-box="0 0 24 24" v-html="path"></svg>
</template>

<script lang="ts" setup>
import type { IconsContainer } from '~~/lib/icons';


const props = defineProps<{
    icon: string;
}>();

const icons = inject('icons') as IconsContainer;

const path = computed(() => icons.icons.get(props.icon))

</script>
