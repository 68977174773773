import { $fetch, FetchError } from 'ofetch'
import { defineStore } from 'pinia';
import { useFlashStore } from '~~/store/flashStore';
import { parseError } from './errors';

const apiFetch = $fetch.create({
    baseURL: process.server ? 'https://m.triya.ru/' : '/',
    // baseURL: process.server ? 'http://lumen.triya.ru/' : '/',
    // baseURL: process.server ? 'https://tablechair.ru/' : '/',
    // baseURL: process.server ? 'http://st.triya.ru/' : '/',
});


export const useApi = defineStore('api', () => {

    const activeRequests = reactive(new Set<string>());

    const flashStore = useFlashStore();

    async function request<T = any>(endpoint: string, body?: any) {

        const app = useNuxtApp();

        try {
            activeRequests.add(endpoint);

            const params: any = {
                body: body,
                method: 'post',
                responseType: 'json',
            };

            if (app.ssrContext && !endpoint.startsWith('https://')) {
                params['headers'] = useRequestHeaders(['cookie', 'x-forwarded-for']) as any;
                if (!params.body) {
                    params.body = {};
                }
                params.body.remoteAddress = params['headers']['x-forwarded-for'] ?? null;
            }

            return await apiFetch<T>(endpoint, params);

        } catch (error) {

            if (error instanceof FetchError && error.response?._data?.public_error) {
                const info = parseError(error.response._data);

                flashStore.setFlash(info.message, 'error', 5000);

                if (!info.stop) {
                    throw error;
                }
            } else if (error instanceof FetchError) {
                throw createError({statusCode: error.statusCode});
            } else {
                flashStore.setFlash('Неизвестная ошибка', 'error', 5000);
                throw error;
            }
        }finally {
            activeRequests.delete(endpoint);
        }
    }

    const pending = computed(() => !!activeRequests.size)

    return { request, pending };
});

