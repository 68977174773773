<template>
    <div class="section-container" v-if="mainPage">
        <div class="item" v-for="section of mainPage.childs" :key="section.page" @click="openMenu(section.page!)">
            <img :src="section.icon">
            {{section.name}}
        </div>
    </div>
</template>

<script setup lang="ts">
import { useMenuStore } from '~/store/menuState';


const menuStore = useMenuStore();

const mainPage = menuStore.mainMenu?.find((el) => el.code == 'root');

function openMenu(code: string) {
    menuStore.open(code);
}


</script>

<style lang="scss" scoped>

@import '~/assets/css/colors.scss';

.section-container {
    display: flex;
    flex-wrap: wrap;

    
    grid-template-columns: repeat(3, 1fr);

    @media(min-width: 480px) {
        grid-template-columns: repeat(4, 1fr);
    }

    border-collapse: collapse;

    border-top: 1px solid $color-grey-middle;
    border-bottom: 1px solid $color-grey-middle;

    .item {
        outline: 1px solid #ccc;
        flex: 1;
        min-width: 120px;
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        align-items: center;

        padding: 10px 0;

        text-decoration: none;
        color: inherit;

        transition: background-color .1s ease;
        background-color: white;

        img {
            height: 35px;
            margin-bottom: 10px;
        }

        &:hover {
            background-color: var(--bg1);
        }
    }

}

</style>