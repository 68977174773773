import { default as indexvIO8GFt3J2Meta } from "/builds/triyaru/triya-mobile-frontend-2/pages/3d/index.vue?macro=true";
import { default as onlinezld7DjTPalMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/3d/online.vue?macro=true";
import { default as detail7vyCbitBstMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/company/detail.vue?macro=true";
import { default as companykAP6drHPmuMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/index/company.vue?macro=true";
import { default as contactsoix5Q1tvAxMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/index/contacts.vue?macro=true";
import { default as deffered_45paymentRk0Usi1tjZMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/index/deffered-payment.vue?macro=true";
import { default as ecologySQMpEu1csJMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/index/ecology.vue?macro=true";
import { default as productionE4dXBcfdB5Meta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/index/production.vue?macro=true";
import { default as indexlml3IUyJ3XMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/index/stores/index.vue?macro=true";
import { default as warrantyOE7f0Z01PzMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/index/warranty.vue?macro=true";
import { default as index3hqXAqeOvGMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/about/index.vue?macro=true";
import { default as indexaTedMjbx0vMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/catalog/[path]/[code]/index.vue?macro=true";
import { default as indexyNiYGspqNbMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/catalog/[path]/index.vue?macro=true";
import { default as indexhLAP5XtkmJMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/catalog/index.vue?macro=true";
import { default as indexPvsDDrFQ62Meta } from "/builds/triyaru/triya-mobile-frontend-2/pages/index.vue?macro=true";
import { default as indexr5U961jOKGMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/login/index.vue?macro=true";
import { default as _91code_938lc962Ja4BMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/news/[code].vue?macro=true";
import { default as index3vjjI6NrFZMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/news/index.vue?macro=true";
import { default as indexagGElKumCDMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/personal/cart/[id]/index.vue?macro=true";
import { default as indexHTooF6gvcDMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/personal/cart/index.vue?macro=true";
import { default as indexcEvkaRL7I4Meta } from "/builds/triyaru/triya-mobile-frontend-2/pages/personal/favorite/index.vue?macro=true";
import { default as indextfIw9U9QCtMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/personal/index.vue?macro=true";
import { default as indexX34Mav3bjsMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/promo/[path]/index.vue?macro=true";
import { default as index1RS5phItwKMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/promo/credit/index.vue?macro=true";
import { default as indexH8ieB233MlMeta } from "/builds/triyaru/triya-mobile-frontend-2/pages/search/index.vue?macro=true";
export default [
  {
    name: "3d",
    path: "/3d",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/3d/index.vue")
  },
  {
    name: "3d-online",
    path: "/3d/online",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/3d/online.vue")
  },
  {
    name: "about-company-detail",
    path: "/about/company/detail",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/company/detail.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/index.vue"),
    children: [
  {
    name: "about-index-company",
    path: "company",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/index/company.vue")
  },
  {
    name: "about-index-contacts",
    path: "contacts",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/index/contacts.vue")
  },
  {
    name: "about-index-deffered-payment",
    path: "deffered-payment",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/index/deffered-payment.vue")
  },
  {
    name: "about-index-ecology",
    path: "ecology",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/index/ecology.vue")
  },
  {
    name: "about-index-production",
    path: "production",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/index/production.vue")
  },
  {
    name: "about-index-stores",
    path: "stores",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/index/stores/index.vue")
  },
  {
    name: "about-index-warranty",
    path: "warranty",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/about/index/warranty.vue")
  }
]
  },
  {
    name: "catalog-path-code",
    path: "/catalog/:path()/:code()",
    meta: indexaTedMjbx0vMeta || {},
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/catalog/[path]/[code]/index.vue")
  },
  {
    name: "catalog-path",
    path: "/catalog/:path()",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/catalog/[path]/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/catalog/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexr5U961jOKGMeta || {},
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/login/index.vue")
  },
  {
    name: "news-code",
    path: "/news/:code()",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/news/[code].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/news/index.vue")
  },
  {
    name: "personal-cart-id",
    path: "/personal/cart/:id()",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/personal/cart/[id]/index.vue")
  },
  {
    name: "personal-cart",
    path: "/personal/cart",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/personal/cart/index.vue")
  },
  {
    name: "personal-favorite",
    path: "/personal/favorite",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/personal/favorite/index.vue")
  },
  {
    name: "personal",
    path: "/personal",
    meta: indextfIw9U9QCtMeta || {},
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/personal/index.vue")
  },
  {
    name: "promo-path",
    path: "/promo/:path()",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/promo/[path]/index.vue")
  },
  {
    name: "promo-credit",
    path: "/promo/credit",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/promo/credit/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/builds/triyaru/triya-mobile-frontend-2/pages/search/index.vue")
  }
]