<template>
    <button class="btn" :class="{loading: props.loading, disabled: props.disabled}" @click="onClick">
        <slot/>
        <common-loader class="any-size fit" v-if="loading"></common-loader>
    </button>
</template>

<script setup lang="ts">

const props = defineProps<{
    disabled?: boolean,
    loading?: boolean,
}>();

const emits = defineEmits(['click']);

function onClick() {
    if (!props.disabled && !props.loading) {
        emits('click');
    }
}

</script>
