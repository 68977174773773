import type { IDescriptions } from "~~/types/catalog";

export function useDescriptions(desciptions?: IDescriptions, noindex: boolean = false) {

    const meta = [];
    const link = [];

    const route = useRoute();

    if (desciptions?.canonical) {
        let canonical = desciptions.canonical;
        if (canonical.startsWith('/')) {
            canonical = `https://www.triya.ru${canonical}`;
        }

        link.push({rel: 'canonical', key: 'canonical', href: desciptions.canonical});
    } else {

        let path = route.path;

        switch (route.name) {
            case 'catalog-path-code':
                if (typeof route.query.model === 'string') {
                    path += `?model=${route.query.model}`;
                }
                break;
        }

        link.push({rel: 'canonical', key: 'canonical', href: `https://www.triya.ru${path}`});
    }

    if (desciptions?.description) {
        meta.push({
            key: 'descriptions',
            name: 'descriptions',
            content:  desciptions.description
        })
    }

    if (noindex) {
        meta.push({
            key: 'robots',
            name: 'robots',
            content: 'noindex'
        })
    }

    useHead({
        title: desciptions?.title ?? undefined,
        meta,
        link
    })
}