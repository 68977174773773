<template>
    <div class="small-cart">
        <div class="backdrop visible" @click="cartStore.showDiff = false"></div>
        <div class="content">
            <div class="title">
                {{headerLabel}}
            </div>
            <div class="inner">
                <offer-item v-for="item of cartStore.diff" :item="item" :offers="cartStore.offers"></offer-item>
            </div>
            <div class="footer">
                <div class="btn white" @click="cartStore.showDiff = false">Продолжить покупки</div>
                <nuxt-link to="/personal/cart/" class="btn red">Оформить заказ</nuxt-link>
            </div>
        </div>
    </div>
</template>


<script setup lang="ts">

import { useCartStore } from '~/store/cartStore';
import { useDialogStore } from '~/store/dialogStore';
import offerItem from './item.vue';

const cartStore = useCartStore();
const dialogStore = useDialogStore();
const router = useRouter();


router.afterEach(() => {
    cartStore.showDiff = false;
});


watch(() => cartStore.showDiff, (v) => {
    dialogStore.set('small-cart', v);
});


const headerLabel = computed(() => {
    return cartStore.diff.length > 1 ? 'Товары добавлены в корзину' : 'Товар добавлен в корзину';
});

</script>


<style lang="scss" scoped>
.small-cart {
    .title {
        padding: 5px 10px;
        font-size: 18px;
        font-weight: 500;
    }

    .content {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        border-top: 2px solid #ccc;
        // height: 400px;
        z-index: 1000;
        
    }

    .inner {
        overflow-y: auto;
        max-height: calc(80vh - 100px);
    }

    .footer {
        display: flex;
        padding: 5px 10px;
        gap: 10px;
        justify-content: space-between;
    }
}
</style>