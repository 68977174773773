import { useMainStore } from "~/store/mainState";
import { useUserStore } from "~/store/userStore";

export default defineNuxtRouteMiddleware(async (to, from) => {

    const mainStore = useMainStore();

    await mainStore.tryInit();

    const userStore = useUserStore();

    if (!userStore.user) {
        return navigateTo('/');
    }
});
