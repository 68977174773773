import { defineStore } from "pinia";
import { useUserStore } from "./userStore";

export const usePersonalData = defineStore('personalData', () => {

    const personalUser = reactive({
        name: '',
        phone: '',
    });

    const userStore = useUserStore();

    watch(() => userStore.user, () => updateUser());

    function updateUser(reset: boolean = true) {
        if (userStore.user) {
            personalUser.name = userStore.user.firstName;
            personalUser.phone = userStore.user.phone ?? '';
        } else if (reset) {
            personalUser.name = '';
            personalUser.phone = '';
        }
    }

    updateUser();

    const address = ref('');

    const payType = ref(1);

    const comment = ref('');
    const credit = ref('installment_0_0_3_2,93');
    const isCredit = computed(() => [10, 14].includes(payType.value));

    return { updateUser, personalUser, address, payType, comment, credit, isCredit }
});
