import { apiFetch, useApi } from "~/lib";

export default defineNuxtRouteMiddleware(async (to, from) => {

    if (!import.meta.server) {
        return;
    }

    if (!to.matched.length) {
        const matched = /^\/([A-z0-9]{10,11})\/?$/.exec(to.fullPath);

        if (matched?.length) {
            const key = matched[1];
            try {
                const params: any = {
                    body: {key},
                    method: 'post',
                    responseType: 'json',
                };
                const link = await apiFetch('https://m.triya.ru/rest/short', params);
                if (link?.link) {
                    return navigateTo(link.link);
                }
            } catch {}
        }
    }
});
