<template>
    <landing :blocks="data.data.value.landing"></landing>
</template>

<script lang="ts" setup>
import { useDescriptions } from "~/lib/descriptions";
import { useApi } from "~~/lib"

const api = useApi();

const data = await useAsyncData('index', () => api.request('/rest/page', {mobile: true}));

useDescriptions(data.data.value.meta);

</script>
