<template>
    <NuxtLayout name="error">
        <div class="inner-body-block error-page">

            <div class="error-code" :class="`error-code-${statusCode}`">
                {{ statusCode }}
            </div>

            <div class="error-caption">
                {{ errorCaption }}
            </div>

            <div class="error-description">{{ errorDescription }}</div>

            <nuxt-link to="/" class="btn transparent-red">Вернуться на главную</nuxt-link>
        </div>
  
    </NuxtLayout>
</template>

<script setup lang="ts">
const error = useError();

const statusCode = computed(() => {
    if (!(error.value instanceof Error) && typeof error.value?.statusCode !== 'undefined') {
        return error.value?.statusCode;
    }

    return 500;
})

const errorCaption = computed(() => {
    if (!(error.value instanceof Error) && typeof error.value?.statusCode !== 'undefined') {
        switch (error.value?.statusCode) {
            case 404:
                return 'Такая страница не найдена';
            case 500:
                return 'Ошибка сервера';
        }
    }

    return 'Неизвестная ошибка'
});

const errorDescription = computed(() => {
    if (!(error.value instanceof Error) && typeof error.value?.statusCode !== 'undefined') {
        switch (error.value?.statusCode) {
            case 404:
                return 'Неправильно набран адрес или такой страницы не существует.';
            case 500:
                return 'Проблема у нас, а не у Вас.\n'
                        + 'Мы уже делаем всё,\n'
                        + 'чтобы это исправить!';
        }
    }

    return null
})

</script>

<style lang="scss" scoped>

.error-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.error-code {

    text-align: center;
    font-size: min(30vw, 120px);
    font-weight: 500;
    position: relative;

    background: repeating-linear-gradient(42deg, #71b7e6, #69a6ce, #bc4a9b, #f6757a, #bc4a9b, #285cc4, #403353);
    background-clip: text;
    background-size: 600% 600%;
    -webkit-text-fill-color: transparent;
    animation: bgAnimation 25s ease-in-out infinite;
}

@keyframes bgAnimation {
    0% {
		background-position: 0% 50%;
	}
	25% {
		background-position: 75% 0%;
	}
	50% {
		background-position: 99% 99%;
	}
	75% {
		background-position: 50% 25%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.error-code-500 {
    background-image: repeating-linear-gradient(42deg, #df3e23, #fa6a0a, #b4202a, #ee8176, #73172d, #fa6a0a, #3b1725);
}

.error-caption {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: var(--t3);
}

.error-description {
    text-align: center;
    margin-bottom: 40px;
}


</style>