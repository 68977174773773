import { defineStore } from "pinia";
import { useApi } from "~~/lib";
import type { IMenuItem, IMenuPage } from "~~/types/menu";
import type { IRegion } from "~~/types/region";
import { useCartStore } from "./cartStore";
import { useFavoriteStore } from "./favoriteState";
import { useRegionStore } from "./regionStore";
import { useUserStore } from "./userStore";

export const useMenuStore = defineStore('menu', () => {


    const mainMenu: Ref<null | IMenuPage[]> = ref(null);
    const active = ref(false);
    const page = ref("root");

    const api = useApi();

    function open(code?: string) {
        if (code) {
            page.value = code;
        } else {
            page.value = 'root'
        }

        active.value = true;
    }

    async function tryInit() {
        // console.log(mainMenu.value)
        if (!mainMenu.value?.length) {
            return init();
        }
    }

    async function init() {

        const data = await api.request('/rest/catalog/menu/mobile');
        mainMenu.value = data;
    }



    return {tryInit, open, mainMenu, active, page};
});
