export default defineNuxtPlugin(nuxtApp => {

    let format = 'jpeg';

    if (nuxtApp.ssrContext) {

        const accept = nuxtApp.ssrContext.event.node.req.headers.accept;
        // if (accept?.includes('image/avif')) {
        //     format = 'avif';
        // } else 
        if (accept?.includes('image/webp')) {
            format = 'webp';
        }

        useState('image-format', () => format)

    } else {
        format = (useState('image-format').value ?? 'webp') as string;
    }

    nuxtApp.vueApp.provide('image-format', format);
});