<template>
    <div class="header-search-container">
        <div class="field">
            <input :value="searchQuery" @input="event => searchQuery = event.target!.value" @focus="open()" placeholder="Найдите нужный товар" class="simple-input full-width" ref="inputField">
            <img class="search-btn" v-if="standalone" src="~/assets/icons/header/search.svg" alt="Поиск"/>
            <span class="close-btn" v-else @click="emits('close')">&times;</span>
        </div>
    </div>
    <div class="backdrop visible" v-if="!standalone || modelValue" @click="close()"></div>

    <div class="search-result" v-if="!standalone || modelValue && searchResult.length">
        <nuxt-link :to="offer.link" class="search-item" v-for="offer of searchResult" :key="offer.id">
            <common-number-picture size="search" scallable :src="offer.pictures[0]"></common-number-picture>
            <div class="description">
                <span class="title">{{offer.name}}</span>
                <div class="price-block">
                    <div class="price currency" :class="{'with-discount': !!offer.price.oldPrice}">{{$money(offer.price.price)}}</div>
                    <div class="old-price currency" v-if="offer.price.oldPrice">{{$money(offer.price.oldPrice)}}</div>
                </div>
            </div>
        </nuxt-link>
        <nuxt-link v-if="searchResult.length" :to="searchLink" class="result-button">
            Ещё результаты
        </nuxt-link>
    </div>

</template>

<script lang="ts" setup>
import { debounce } from 'lodash-es';
import type { Ref } from 'vue';
import { useApi } from '~~/lib';
import type { IOffer } from '~~/types/catalog';

const props = defineProps<{
    modelValue?: boolean;
    standalone?: boolean;
}>();

const emits = defineEmits<{
    close: [],
    'update:modelValue': [boolean]
}>();

const searchQuery = ref('');
const searchResult: Ref<IOffer[]> = ref([]);
const loading = ref(false);

const api = useApi();

const debouncedSearch = debounce(() => {
    search(searchQuery.value);
}, 300)

function close() {

    searchQuery.value = '';
    searchResult.value = [];

    emits('close');
    emits('update:modelValue', false);
}

function open() {
    emits('update:modelValue', true);
}

const router = useRouter();

router.afterEach(() => {
    close();
});


watch(searchQuery, () => debouncedSearch());

async function search(query: string) {

    if (query.length < 3 || loading.value) {
        searchResult.value = [];
        return;
    }

    loading.value = true;

    try {

        const data = await api.request('/rest/catalog/search', {
            search: query,
            mini: true
        });

        searchResult.value = data.offers;
    } catch (err) {
        console.error(err);

        searchResult.value = [];
    }

    loading.value = false;

    // если строка запроса изменилась, пока мы искали
    if (query !== searchQuery.value) {
        // то мы ищем снова
        search(searchQuery.value);
    }
}


const inputField: Ref<HTMLElement | null> = ref(null);
onMounted(() => {
    if (!props.standalone) {
        inputField.value?.focus();
    }
})


const searchLink = computed(() => {
    return '/search/?q=' + encodeURI(searchQuery.value.trim());
});

</script>

<style lang="scss" scoped>

@import '~/assets/css/colors.scss';

.header-search-container {
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: var(--bg0);
    padding: 7px 14px;
    // background-color: ;
}

.field {
    position: relative;
    width: 100%;
}

.close-btn {
    font-size: 35px;
    line-height: 35px;
    padding: 0 6px;
    top: 1px;
    position: absolute;
    right: 6px;
}
.search-btn {
    padding: 0 6px;
    top: 6px;
    pointer-events: none;
    position: absolute;
    right: 0px;
}

.search-result {
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1001;
    background-color: white;
    border-bottom: 2px solid $color-grey-light;
    color: $color-text;
    max-height: calc(100dvh - 60px);
    overflow-y: auto;

    a {
        color: $color-text;
        text-decoration: none;
    }

    &>.search-item {
        display: flex;
        padding: 10px 15px;
        font-size: 16px;
        &:hover {
            background: rgba(0,0,0,0.2);
        }

        :deep(img) {
            width: 100px;
            height: 54px;
            flex-shrink: 0;
            border-radius: 4px;
        }

        .description {
            margin-left: 10px;
            flex-direction: column;
            justify-content: space-between;
            display: flex;

            .title {
                color: inherit;
                text-decoration: inherit;
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                }
            }

            .price-block {
                display: flex;
                align-items: center;
                font-size: 16px;

                .price {
                    font-weight: 500;

                    &.with-discount {
                        color: $color-text-red;
                    }
                }

                &>.old-price {
                    color: #999;
                    font-size: 12px;
                    text-decoration-line: line-through;
                    margin-left: 5px;
                }
            }
        }
    }
    
    .result-button {
        font-size: 16px;
        font-weight: 500;
        padding: 5px 10px;
        display: block;
        border-top: 1px solid  $color-grey-light;
    }
}

</style>