<template>
    <div class="loader-showbox">
        <div class="loader">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10"></circle>
            </svg>
        </div>
    </div>
</template>

<style lang="scss" src="./style.scss" scoped>
</style>
