<template>
    <div class="small-cart-item" v-if="offer">
        <nuxt-link :to="offer.link" class="cart-offer-picture">
            <common-number-picture :src="offer.picture" size="m-offer-smol"/>
        </nuxt-link>
        <div class="offer-data">
            <nuxt-link class="name" :to="offer.link">
                {{offer.name}}
            </nuxt-link>

            <div class="prop" v-if="offer.article">#{{offer.article}}</div>
            <div class="prop" v-if="offer.size">Размер: {{offer.size}} см</div>
            <div class="prop" v-if="offer.color">Цвет: {{offer.color}}</div>


            <div class="price">
                <div class="quantity" v-if="item.quantity > 1">{{item.quantity}} шт</div>
                <div class="current currency" :class="{'with-discount': !!item.discount}">{{ $money(currentPrice) }}</div>
                <div class="old currency" v-if="item.discount">{{ $money(oldPrice) }}</div>
            </div>

            
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ICartItem, ICartOffer } from '~/types/cart';

const props = defineProps<{
    item: ICartItem;
    offers: Map<number, ICartOffer>
}>();

const offer = computed(() => props.offers.get(props.item.id));

const currentPrice = computed(() => props.item.price);
const oldPrice = computed(() => props.item.price + (props.item.discount ?? 0));

</script>


<style lang="scss" scoped>

@import '~/assets/css/colors.scss';

.small-cart-item{

    padding: 0 10px;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    img {
        border-radius: 4px;
    }

    .prop {
        color: $color-text-light;
        margin-bottom: 2px;
        font-weight: 300;
        font-size: 12px;
    }

    .price {

        display: flex;
        gap: 5px;
        align-items: flex-end;
        margin-bottom: 10px;

        .current {
            font-size: 22px;
            font-weight: 500;
            &::after{
                font-size: 20px;
            }

            &.with-discount {
                color: $color-text-red;
            }
        }

        .quantity {
            font-size: 22px;
            font-weight: 500;

            &::after {
                content: ' \00D7';
                font-size: 20px;
            }
        }

        .old {
            font-size: 16px;
            font-weight: 300;
            color: $color-grey-dark;
            text-decoration: line-through;
            &::after{
                font-size: 14px;
            }
        }
    }

    .lower-block {
        padding: 0 15px 5px 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .remove-btn {
        color: $color-text-light;
    }

    .cart-offer-picture {
        position: relative;
        flex-shrink: 0;
        flex-basis: 96px;

        .express-icon {
            position: absolute;
            top: 5px;
            left: 0;
            height: 34px;
        }
    }

    .name {
        // padding: 5px 15px;
        width: 100%;
        display: flex;
        align-items: center;
        color: $color-text;
        text-decoration: none;
        font-size: 16px;
    }
}

</style>