<template>
    <div v-if="modelValue" class="backdrop visible" @click="close">
        <div class="close-btn">&times;</div>
    </div>
    <div class="main-menu" v-show="modelValue">

        <search-component standalone v-if="menuStore.page === 'root'" v-model="showSearch"></search-component>

        <div class="scrollable-menu">
            <page-component v-if="activePage" :page="activePage" @page="menuStore.page = $event">
                <div class="additional main-menu-item" @click="showCitySelector = true">
                    <div class="menu-item-icon"><img src="~/assets/icons/menu/city-selector.svg" aria-hidden></div>

                    <span class="menu-item-label two-line">
                        <div>{{ region.name }}</div>
                        <div class="sub" v-if="city && city.name !== region.name">{{ city.name }}</div>
                    </span>
                    
                </div>
                <nuxt-link v-if="userStore.user" to="/personal" class="additional main-menu-item">
                    <div class="menu-item-icon">
                        <img src="~/assets/icons/menu/user.svg" aria-hidden>
                    </div>

                    <div class="two-line menu-item-label">
                        <div>{{ userStore.user?.firstName }}</div>
                        <div class="sub">Личный кабинет</div>
                    </div>

                </nuxt-link>
                <nuxt-link v-else to="/login" class="additional main-menu-item">
                    <div class="menu-item-icon">
                        <img src="~/assets/icons/menu/user.svg" aria-hidden>
                    </div>

                    <span class="menu-item-label">Личный кабинет</span>
                </nuxt-link>

                <nuxt-link v-if="mainStore.ordersCount" to="/personal#order_list" class="additional main-menu-item">
                    <div class="menu-item-icon">
                        <img src="~/assets/icons/menu/order-list.svg" aria-hidden>
                    </div>

                    <div class="menu-item-label">
                        Заказы
                        <div class="badge" v-if="mainStore.ordersCount">{{ mainStore.ordersCount }}</div>
                    </div>

                </nuxt-link>

                <nuxt-link to="/personal/favorite" class="additional main-menu-item">
                    <div class="menu-item-icon">
                        <img src="~/assets/icons/menu/favorite.svg" aria-hidden>
                    </div>

                    <span class="menu-item-label">
                        Избранное
                        <div class="badge" v-if="favoriteStore.count">{{ favoriteStore.count }}</div>
                    </span>
                </nuxt-link>

                <nuxt-link v-for="(link, i) of links" :key='i' class="main-menu-item" :to="link.link">
                    <span class="menu-item-label">
                        {{link.name}}
                        <div class="badge grey" v-if="link.link === '/about/stores/' && region.cntStores">{{ region.cntStores }}</div>
                    </span>
                    <common-svg-icon class="menu-item-space menu-item-arrow" icon="angle-down"></common-svg-icon>
                </nuxt-link>

                <template v-slot:phone>
                    <a class="phone additional item" v-if="region.phones.length" :href="`tel:${phone}`">
                        <div class="item-icon"><img src="~/assets/icons/menu/phone.svg"></div>
                        {{ phone }}
                    </a>
                </template>
            </page-component>
        </div>
    </div>

    <client-only>
        <city-selector v-if="showCitySelector" @close="showCitySelector = false"></city-selector>
    </client-only>
</template>

<script lang="ts" setup>

import { useMenuStore } from '~/store/menuState';
import citySelector from './city-selector/index.vue';
import searchComponent from '../search/index.vue';
import pageComponent from './page/index.vue'

import { useMainStore } from "~~/store/mainState";
import { useUserStore } from '~~/store/userStore';
import { useFavoriteStore } from '~/store/favoriteState';

const props = defineProps(['modelValue']);
const emits = defineEmits(['update:modelValue']);

const userStore = useUserStore();

const favoriteStore = useFavoriteStore();

const menuStore = useMenuStore();
const mainStore = useMainStore();


const router = useRouter();

router.afterEach(() => {
    close();
});

const showSearch = ref(false);

const region = computed(() => mainStore.region!);
const city = computed(() => mainStore.city);
const menu = computed(() => menuStore.mainMenu);

useLazyAsyncData(() => useMenuStore().tryInit())

function close() {
    showSearch.value = false;
    emits('update:modelValue', false);
}

const phone = computed(() => {
    return region.value.phones[0];
});

const showCitySelector = ref(false);

const activePage = computed(() => {
    return menu.value?.find(el => el.code === menuStore.page);
})


const links = [
    {
        name: 'О компании',
        link: '/about/company/',
    },
    {
        name: 'О продукции',
        link: '/about/production/',
    },
    {
        name: 'Адреса магазинов',
        link: '/about/stores/',
    },
    {
        name: 'Оплата и доставка',
        link: '/about/delivery/',
    },
    {
        name: 'Контакты',
        link: '/about/contacts/',
    }
];

</script>

<style lang="scss" scoped src="./style.scss"></style>