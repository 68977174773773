<template>
    <div class="app-header-placeholder"></div>
    <header class="app-header" ref="headerElement">
        <div class="menu-button" @click="menuStore.active = true">
            <img src="~/assets/icons/header/menu.svg" aria-hidden="true" height="32"/>
        </div>

        <nuxt-link to="/">
            <img class="logo" src="~/assets/icons/header/logo.svg" aria-hidden="true"/>
        </nuxt-link>

        <div class="weak-spacer"></div>

        <a href="/search/" rel="nofollow" @click.prevent="showSearch = true">
            <img src="~/assets/icons/header/search.svg" alt="Поиск" width="27" height="28"/>
        </a>

        <nuxt-link to="/personal/cart/" rel="nofollow" class="header-icon">
            <img src="~/assets/icons/header/basket.svg" alt="Корзина" width="34" height="32"/>
            <div class="badge" v-if="cartStore.cartItemsCount">{{cartStore.cartItemsCount}}</div>
        </nuxt-link>

        <header-search v-if="showSearch" @close="showSearch = false"></header-search>
    </header>
    <client-only>
        <header-flash></header-flash>
    </client-only>
    
    <nuxt-lazy-hydrate :when-triggered="menuStore.active">
        <header-side-menu v-model="menuStore.active"></header-side-menu>
    </nuxt-lazy-hydrate>
</template>

<script lang="ts" setup>
import { useMenuStore } from "~/store/menuState";
import { useCartStore } from "~~/store/cartStore";
import { useDialogStore } from "~~/store/dialogStore";
import { useMainStore } from "~~/store/mainState";

const headerElement: Ref<HTMLElement | null> = ref(null);

const menuStore = useMenuStore();
const cartStore = useCartStore();

const showSearch = ref(false);

onMounted(() => {
    if (!headerElement.value) {
        return;
    }

    const target = headerElement.value;

    const rect = target.getBoundingClientRect();

    let headerPosition = Math.max(0, window.scrollY - rect.height);
    let lastPosition = window.scrollY;
    target.style.top = `${headerPosition}px`;

    document.addEventListener('scroll', () => {

        const scroll = Math.max(window.scrollY, 0);

        if (lastPosition > scroll && headerPosition + rect.height < scroll) {
            headerPosition = scroll - rect.height;
            setAbsoluteHeader(headerPosition);
            target.style.top = `${headerPosition}px`;
        } else if (headerPosition >= scroll) {
            headerPosition = scroll;
            setFixedHeader();
        } else if (lastPosition < scroll) {
            setAbsoluteHeader(headerPosition);
        }

        lastPosition = scroll;
        }, {passive: true})

});

function setFixedHeader() {
    if (headerElement.value) {
        const el = headerElement.value;
        el.style.top = '0';
        el.style.position = 'fixed';
    }
}

function setAbsoluteHeader(px: number) {
    if (headerElement.value) {
        const el = headerElement.value;
        el.style.top = `${px}px`;
        el.style.position = 'absolute';
    }
}


const dialogStore = useDialogStore();

watch(showSearch, () => dialogStore.set('suggest-search', showSearch.value));
watch(() => menuStore.active, () => dialogStore.set('main-menu', menuStore.active));

</script>


<style lang="scss" scoped>

@import '~/assets/css/colors.scss';

.app-header-placeholder {
    height: 60px;
    width: 100%;
}

.app-header {
    height: 60px;
    top: 0;
    width: 100%;
    position: absolute;
    padding: 0 var(--gap-size);
    gap: var(--gap-size);
    background: white;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);

    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 900;

    // @supports (backdrop-filter: none) {
    //     background-color: rgba(255, 255, 255, .95);
    //     backdrop-filter: blur(14px);
    // }
}

.logo {
    height: 42px;
}

.header-icon {
    position: relative;
    height: 32px;

    .badge {
        position: absolute;
        right: -6px;
        top: -4px;
        background: $color-red;
        min-width: 16px;
        height: 16px;
        border-radius: 50%;
        text-align: center;
        color: white;
        font-weight: 500;
        box-shadow: 0 0 0 2px white;
    }
}

</style>