import { defineStore } from "pinia";
import type { IUser } from "~~/types/user";

export const useUserStore = defineStore('user', () => {
    const user = ref<IUser | null>(null);

    function setUser(value: IUser | null) {
        user.value = value;
    }

    return { user, setUser };
});
