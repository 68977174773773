function getFomattedTime(date: Date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
}

const extReplaceMap = new Map([
    ['jpg', 'jpeg']
]);


export default defineNuxtPlugin(() => {
    return {
        provide: {
            money(money: number) {
                return money.toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
            },
            date(value: string | Date) {
                if (typeof value === 'string') {
                    value = new Date(value);
                }

                return value.toLocaleDateString('ru-RU');
            },
            orderDate(value: string | Date) {

                if (typeof value === 'string') {
                    value = new Date(value);
                }

                const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

                const month = value.getMonth();

                let result = `${value.getDate()} ${months[value.getMonth()]}`;

                if (new Date().getFullYear() !== value.getFullYear()) {
                    result += ` ${value.getFullYear()} года`;
                }

                return result;

            },
            localtime(value: string | Date) {
                if (typeof value === 'string') {
                    value = new Date(value);
                }

                if (value.toDateString() !== new Date().toDateString()) {
                    return `${value.toLocaleDateString('ru-RU')}, ${getFomattedTime(value)}`;
                }
            
                return `Сегодня, ${getFomattedTime(value)}`;
            },
            filesize(value: number) {
                if (!value) {
                    return '';
                }
            
                const types = ['байт', 'Кб', 'Мб', 'Гб'];
            
                let type = 0;
            
                while (value > 1000) {
                    value /= 1024;
                    type++;
                }
            
                return `${type ? value.toFixed(1) : value} ${types[type]}`;
            },
            fileext(value: string) {
                if (!value) {
                    return '';
                }
            
                let parts = value.split('.');
                
                if (!parts.length) {
                    return '';
                }
            
                const ext = parts[parts.length - 1].toLowerCase();
            
                if (extReplaceMap.has(ext)) {
                    return extReplaceMap.get(ext);
                }
            
                return ext;
            }
        }
    }
});
