<template>
    <div class="header inner-body-block" v-if="data.header.text.length" :class="[data.header.align, data.style]">
        <nuxt-link v-if="data.header.link" class="title" :to="data.header.link">{{data.header.text}}</nuxt-link>
        <span v-else class="title" :class="data.header.align">{{data.header.text}}</span>
    </div>
    <template v-if="data.secondary">
        <text-block-component :class="data.style" :data="data.secondary" v-if="data.secondary.type === 'text'"/>
        <picture-block-component :data="data.secondary" v-if="data.secondary.type === 'pictures'"/>
    </template>

    <swiper
        :slides-per-view="slides"
        :space-between="spaceBetween"
        :autoplay="autoplay"
        :loop="data.flags.includes('looped')"
        class="inner-body-block offer-list"
        :modules="modules"
        :scrollbar="{
            el: '.swiper-scrollbar'
        }"
        :class="[sliderClass, `count-${slides}`, data.style]"
        v-bind="swiperMarkingParams"
    >
        <template v-if="data.content.type === 'offers'">
            <swiper-slide v-for="offer of data.content.offers" :key="offer.id">
                <catalog-offer :offer="offer"></catalog-offer>
            </swiper-slide>
            <div class="swiper-scrollbar"></div>
        </template>
        <template v-else-if="data.content.type === 'pictures'">
            <swiper-slide class="image grid-image"  v-for="picture of data.content.pictures" :key="picture.src">
                <common-picture :to="picture.href" :src="picture.src" :eager="data.content.flags.includes('eager')" ></common-picture>
            </swiper-slide>
        </template>
  </swiper>
</template>

<script lang="ts" setup>

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Scrollbar, Autoplay } from 'swiper/modules';
import type { ISliderBlock } from '~~/types/landing';

import textBlockComponent from '../text-block.vue';
import pictureBlockComponent from '../picture-block.vue';
import type { AutoplayOptions } from 'swiper/types';

const props = defineProps<{
    data: ISliderBlock
}>();

let slides = 2;
let sliderClass = '';
let spaceBetween = 0;

let autoplay: AutoplayOptions | undefined = undefined;

const modules: any[] = [];

if (props.data.flags.includes('auto')) {
    modules.push(Autoplay);

    autoplay = {
        delay: 2500,
        disableOnInteraction: false
    }
}

if (props.data.content.type === 'pictures') {
    slides = props.data.content.count;

    sliderClass = `grid-image ${props.data.content.size}`
} else if (props.data.content.type === 'offers') {
    spaceBetween = 14;
    switch (props.data.content.size) {
        case 'regular':
        case 'small':
            slides = 2;
            break;
        case 'mini':
            slides = 3;
            break;
        default:
            slides = 2;
            break;
    }

    modules.push(Scrollbar)
}


const swiperMarkingParams = computed(() => {
    if (props.data.content.type === 'offers') {
        return {
            itemscope: true,
            itemType: 'https://schema.org/OfferCatalog'
        }
    }

    return {};
})

</script>

<style lang="scss" scoped>

@import '~/assets/css/colors.scss';


.header{
    margin: var(--gap-size) 0;
}

.header .title {
    color: var(--t1);
    font-size: 20px;
    text-decoration: none;
}

.swiper-slide {
    height: unset;
    align-self: stretch;
}

.swiper-scrollbar {
    height: 5px;
    border-radius: 10px;
    margin-top: 5px;
    background: rgba(0,0,0,.1);
    width: unset;

    :deep(.swiper-scrollbar-drag) {
        background: $color-orange;
        height: 5px;
        border-radius: 10px;
    }
}



</style>