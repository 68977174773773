import { defineStore } from "pinia";
import { useApi } from "~~/lib";
import type { IMenuItem } from "~~/types/menu";
import type { IRegion } from "~~/types/region";
import { useCartStore } from "./cartStore";
import { useFavoriteStore } from "./favoriteState";
import { useRegionStore } from "./regionStore";
import { useUserStore } from "./userStore";
import { useFlashStore } from "./flashStore";

export const useMainStore = defineStore('main', () => {

    const favoriteStore = useFavoriteStore();
    const regionStore = useRegionStore();
    const cartStore = useCartStore();
    const userStore = useUserStore();

    const region: Ref<null | IRegion> = ref(null);
    const city: Ref<null | any> = ref(null);
    const mainMenu: Ref<null | IMenuItem[]> = ref(null);
    const ordersCount = ref(0);
    const shouldAskGeo = ref(false);

    const api = useApi();

    async function tryInit() {
        if (!region.value) {
            return init();
        }
    }

    async function init() {

        const data = await api.request('/rest/info', {
            mobile: true
        });

        if (region.value && data.user && !userStore.user) {
            useFlashStore().setFlash('Успешная авторизация', 'success', 5000);
        }

        userStore.setUser(data.user);

        // @ts-ignore
        region.value = data.region;
        city.value = data.city;
        mainMenu.value = data.menu;

        ordersCount.value = data.orders;

        favoriteStore.count = data.favorites;

        shouldAskGeo.value = data.askGeo;

        cartStore.setCartData(data.cart, false);
    }

    async function login(login: string, password: string) {
        const data = await api.request('/rest/user/login', {
            login, password
        });

        init();
    }
    async function logout() {

        const data = await api.request('/rest/user/logout');

        init();
    }

    async function loginByPhone(phone: string, code: string) {
        const data = await api.request('/rest/auth/use-phone-code', {
            phone, code
        });

        init();
    }

    watch(() => regionStore.currentRegion, () => {
        init();
    })

    return {region, city, shouldAskGeo, init, login, logout, loginByPhone, tryInit, mainMenu, ordersCount};
});
