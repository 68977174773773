<template>
    <span class="base-row-name">Пример комплектации:</span>
    <span class="base-row" v-for="item of usedBase">
        <span class="name">{{ item.name }}</span>
        <span class="amount" v-if="item.amount > 1">{{ item.amount }}шт.</span>
    </span>
</template>

<script setup lang="ts">
import type { IOfferBase } from '~/types/catalog';

const props = defineProps<{
    base: IOfferBase[]
}>();

const usedBase = props.base.slice(0, 2);

</script>

<style lang="scss" scoped>

.base-row-name {
    font-size: 12px;
    font-weight: 500;
}

.base-row {
    display: inline-flex;
    font-size: 12px;
    max-width: 100%;
    gap: 4px;
    line-height: 1;
}

.name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
}

.amount {
    flex-shrink: 0;
}

</style>