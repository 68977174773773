import type { Dictionary } from "lodash";
import type { IErrorEntity, IErrorResponse, IErrorTextResponse } from "~~/types/errors";

const errorMessages: Dictionary<Dictionary<IErrorEntity>> = {
    user: {
        "not-authorized": { message: "Не авторизован", stop: true, actions: [
            {
                text: 'Войти',
                link: '/login/',
                dismiss: true,
            }
        ] },
        "already-authorized": { message: "Уже авторизован", stop: true},
        "already-exists": { message: "Такой пользователь уже существует", stop: true, actions: [
            {
                text: 'Войти',
                link: '/login/',
                dismiss: true,
            }
        ]},
        "user-already-exists": { message: "Такой пользователь уже существует", stop: true, actions: [
            {
                text: 'Войти',
                link: '/login/',
                dismiss: true,
            }
        ]},
        "user-blocked": { message: "Пользователь заблокирован", stop: true },
        "invalid-credentials": { message: "Неверный логин и/или пароль", stop: true },
        "wrong-password": { message: "Неверный пароль", stop: true },
        undefined: { message: "Неизвестная ошибка" }
    },
    cart: {
        "empty": { message: "Корзина пуста", stop: true },
        "item-not-found": { message: "Товар не найден", stop: true },
        "coupon-not-found": { message: "Купон не найден", stop: true },
        "unavailable-pay-type": { message: "Выбранный тип оплаты не доступен", stop: true },
        "unavailable-item": { message: "Товар не доступен к покупке", stop: true },
        "invalid-certificate": { message: "Неверный код сертификата", stop: false },
    },
    order: {
        'not-found': { message: "Заказ не найден", stop: false },
    },
    payment: {
        'already-payed': { message: "Заказ уже оплачен", stop: true },
    },
    auth: {
        'no-such-user': { message: "Такого пользователя не существует", stop: true },
        'attempt-limit-exceeded': { message: "Количество попыток истекло", stop: true },
        'invalid-token': { message: "Срок действия ссылки истёк", stop: true },
        'invalid-captcha': { message: "Неверный код проверки", stop: false },
    }
};

export function parseError(error: IErrorResponse | IErrorTextResponse): IErrorEntity {
    if (error.type === "code") {
        return getCodeError(error);
    } else {
        return {
            message: error.text,
        };
    }
}

function getCodeError(error: IErrorResponse): IErrorEntity {
    if (errorMessages[error.group] && errorMessages[error.group][error.code]) {
        return errorMessages[error.group][error.code];
    }

    return { message: "Неизвестная ошибка" };
}
