<template>
    <div class="main-menu-header" v-if="page.parent">
        <div class="page-back" @click="back">&lsaquo;</div>
        <nuxt-link class="page-title" :to="page.link">{{page.name}}</nuxt-link>
    </div>
    <div class="category-link" v-if="page.parent">
        Перейти в категорию
    </div>
    <div class="catagory-placeholder" v-else></div>
    <div class="menu-page-body">
        <div class="childs">
            <a class="main-menu-item"
                v-for="(child, index) in page.childs"
                :key="index"
                :href="child.link"
                @click.prevent="itemClick($event, child)">
                <div class="menu-item-icon" v-if="child.icon">
                    <img :src="child.icon" aria-hidden>
                </div>
                <span class="menu-item-label">{{child.name}}</span>
                <common-svg-icon class="menu-item-space menu-item-arrow" v-if="child.hasChilds" icon="angle-down"></common-svg-icon>
            </a>
            
        </div>
        <div class="links"  v-if="page.code === 'root'">
            <slot></slot>
            <slot name="phone"></slot>
        </div>

        
    </div>
</template>

<script lang="ts" setup>
import type { IMenuItem, IMenuPage } from '~/types/menu';

const props = defineProps<{
    page: IMenuPage
}>();

const emits = defineEmits<{
    page: [page: string]
}>();


const router = useRouter();

function back() {
    emits('page', props.page.parent ?? 'root')
}

function itemClick(event: MouseEvent, item: IMenuItem) {
    event.preventDefault();
    if (item.hasChilds && item.page) {
        emits('page', item.page)
    } else {
        router.push(item.link);
    }
}

</script>

<style lang="scss" scoped src="./style.scss"></style>