<template>
    <div class="offer-size names" v-if="names.length">
        <span v-for="name of names">{{ name }}</span>
    </div>
    <div class="offer-size values" v-if="names.length">
        <template  v-for="(value, i) of values">
            <span v-if="i">&times;</span>
            <span>{{ value }}</span>
        </template>
    </div>
</template>

<script setup lang="ts">
import type { IOfferLocalProps } from '~/types/catalog';

const props = defineProps<{
    size: IOfferLocalProps[]
}>();

const usedProps = props.size.filter((el) => el.size);

const names = usedProps.map((el) => el.name);
const values = usedProps.map((el) => el.value);

</script>

<style lang="scss" scoped>

.offer-size {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 1;
}

.names {
    color: var(--t2);
}

.values {
    font-weight: 500;
}

</style>