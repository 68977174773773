<template>
    <header-component v-if="mainStore.region"></header-component>

    <slot></slot>

    <footer-component v-if="mainStore.region"></footer-component>

</template>

<script setup lang="ts">

import HeaderComponent from '~/components/header/index.vue'
import FooterComponent from '~/components/footer/index.vue'
import { useMainStore } from '~/store/mainState';

const mainStore = useMainStore();

if (!mainStore.region) {
    mainStore.tryInit();
}

</script>