import { cloneDeep, debounce } from "lodash-es";
import { defineStore, skipHydrate } from "pinia";
import { useApi } from "~~/lib";
import type { ICartContent, ICartDiff, ICartItem, ICartOffer } from "~~/types/cart";
import { useCartSuggestedOffersStore } from "./cartSuggestedOffersStore";
import { useServiceStore } from "./serviceStore";
import { trackCart, trackCartAction } from "~/lib/metrika";
import { usePersonalData } from "./personalDataStore";

export const useCartStore = defineStore('cart', () => {

    const api = useApi();
    const services = useServiceStore();
    const personal = usePersonalData();
    const suggestedOffers = useCartSuggestedOffersStore();

    let concreteContent: ICartItem[] = [];

    const content: Ref<ICartItem[]> = ref([]);
    const diff: Ref<ICartDiff[]> = ref([]);
    const showDiff = ref(false);
    const offers: Map<number, ICartOffer> = shallowReactive(new Map());
    const deliveryTime: Ref<[Date, Date] | null> = ref(null);

    const updatingItems = reactive(new Set<number>());
    const pendingUpdates = new Map<ICartItem, number>();

    const debouncedUpdate = skipHydrate(debounce(() => {
        flushUpdate();
    }, 200));

    async function buy(offer: number) {
        await cartRequest('/rest/cart/add', {
            id: offer,
        });

        showDiff.value = true;

        suggestedOffers.invalidate();
    }

    function flush() {
        content.value = content.value.filter((el) => !el.selected);
    }

    function fakeUpdate(item: ICartItem, quantity: number) {

        if (updatingItems.has(item.id)) {
            return;
        }

        if (!pendingUpdates.has(item)) {
            pendingUpdates.set(item, item.quantity);
        }

        item.quantity = quantity;
        debouncedUpdate();
    }

    function flushUpdate() {
        pendingUpdates.forEach((oldValue, item) => {
            update(item, oldValue);
        });

        pendingUpdates.clear();
    }

    async function update(item: ICartItem, oldQuantity: number) {

        await cartRequest('/rest/cart/update', {
            id: item.id,
            quantity: item.quantity,
        }, item.id, () => {
            item.quantity = oldQuantity;
        });
    }

    async function remove(item: number) {
        await cartRequest('/rest/cart/delete', {
            id: item,
        }, item);

        suggestedOffers.invalidate();
    }

    async function toggle(item: ICartItem) {

        if (updatingItems.has(item.id)) {
            return;
        }

        const oldValue = item.selected;

        item.selected = !oldValue;

        await cartRequest('/rest/cart/toggle', {
            ids: item.grouped ?? [item.id],
            selected: item.selected
        }, item.id, () => {
            item.selected = oldValue;
        });
    }

    async function setServiceCity(city: string) {

        await cartRequest('/rest/cart/set-service-city', {
            city
        });
    }

    async function cartRequest(endpoint: string, params: any, id?: number, rollback?: () => void) {

        if (id) {
            if (updatingItems.has(id)) {
                return;
            }

            updatingItems.add(id);
        }

        try {
            const cartData = await api.request(endpoint, params);

            setCartData(cartData);
        } catch (error) {
            if (rollback) {
                rollback();
            }

            console.error(error)

            throw error;
        } finally {
            if (id) {
                updatingItems.delete(id);
            }
        }
    }

    async function setPayType(id: number) {

        const init = personal.payType;
        personal.payType = id;

        await cartRequest('/rest/cart/set-pay-type', {
            payType: id,
        }, undefined, () => {
            personal.payType = init;
        });
    }


    function setCartData(value: ICartContent, makeDiff = true) {
        value.offers.forEach((offer) => {
            offers.set(offer.id, offer);
        })

        setCartContent(value.content, makeDiff);
        services.setServices(value.services);

        concreteContent = cloneDeep(content.value);

        personal.payType = value.payType;

        if (value.delivery) {
            deliveryTime.value = [new Date(value.delivery[0]), new Date(value.delivery[1])];
        } else {
            deliveryTime.value = null;
        }
    }

    function setCartContent(value: ICartItem[], makeDiff: boolean) {

        const newContent = mathCartContent(value);

        if (makeDiff) {
            const newOffers = findNewOffers(concreteContent, newContent);

            diff.value = [];

            newOffers.forEach((el) => {
                const exists = diff.value.find((old) => old.id === el.id)
                if (exists) {
                    exists.quantity += el.quantity;
                } else {
                    diff.value.push(el);
                }
            });

            if (diff.value.length) {
                trackCartDiff(diff.value, offers)
            }
        }

        content.value = newContent;
    }

    const cartItemsCount = computed(() => {
        return content.value.length;
    })

    const expressDeliveryStatus = computed(() => {
        const remainsMap = new Map<number, number>();

        offers.forEach((value) => {
            remainsMap.set(value.id, value.express);
        });

        content.value.forEach((item) => {
            if (item.selected) {
                remainsMap.set(item.offer, (remainsMap.get(item.offer) || 0) - item.quantity);
            }
        });

        const remains = Array.from(remainsMap.values());

        return [remains.some((el) => el >= 0), remains.some((el) => el < 0)];
    })

    return {content, concreteContent, offers, updatingItems, deliveryTime, diff, showDiff, buy, update: fakeUpdate, remove, toggle, setServiceCity, setCartData, cartItemsCount, expressDeliveryStatus, flush, setPayType};
});

function mathCartContent(content: ICartItem[]) {
    const result = [];

    for (const item of content) {

        const idx = result.findIndex((el: ICartItem) => el.price === item.price && el.offer === item.offer);

        if (idx === -1) {
            item.grouped = [item.id];
            item.key = item.id.toString();
            result.push(item);
        } else {
            result[idx].quantity++;
            result[idx].grouped!.push(item.id);
            item.key = item.offer + '-' + item.price;
        }
    }

    // осуществляем сортировку с целью сгруппировать разные
    // товары с одинаковыми продуктами
    result.sort((a: ICartItem, b: ICartItem) => (a.offer === b.offer) ? (b.quantity - a.quantity) : (a.offer - b.offer));

    return result;
}

function findNewOffers(old: ICartItem[], newContent: ICartItem[]) {
    const oldMap = new Map(old.map((el) => [el.offer, el.quantity]))

    const diff: ICartDiff[] = [];

    newContent.forEach((el) => {
        if (!oldMap.has(el.offer) || oldMap.get(el.offer)! != el.quantity) {
            diff.push({
                id: el.offer,
                quantity: el.quantity - (oldMap.get(el.offer) ?? 0),
                price: el.price,
                discount: el.discount
            });
        }
    });
    old.forEach((el) => {
        if (!newContent.some((n) => el.offer === n.offer)) {
            diff.push({
                id: el.offer,
                quantity: -el.quantity,
                price: el.price,
                discount: el.discount
            });
        }
    });

    return diff;
}

function trackCartDiff(diff: ICartDiff[], offers: Map<number, ICartOffer>) {

    const items = diff.map((el) => {
        const name = offers.get(el.id)!.name;

        return {
            id: el.id,
            price: el.price,
            name,
            quantity: el.quantity
        }
    }) as IMetrikaOffer[];

    if (items.length === 1) {
        trackCartAction(items[0])
    } else {
        trackCart(items);
    }
}
