<template>
    <nuxt-loading-indicator color="#EA2428" :height="3"></nuxt-loading-indicator>
    <header-component></header-component>

    <NuxtPage/>

    <!-- <div class="weak-spacer"></div> -->

    <client-only>
        <lazy-cart-bottom-cart  v-if="cartStore.showDiff && cartStore.diff.length"></lazy-cart-bottom-cart>
    </client-only>

    <footer-component></footer-component>

</template>

<script setup>

import HeaderComponent from '~/components/header/index.vue'
import FooterComponent from '~/components/footer/index.vue'
import { useCartStore } from '~/store/cartStore';

const cartStore = useCartStore();

</script>