<template>
  <NuxtLayout></NuxtLayout>
</template>

<script lang="ts" setup>
import { useMainStore } from "./store/mainState";

await useAsyncData('main', async () => {
    await useMainStore().tryInit();
})

</script>
