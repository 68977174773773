import { useDialogStore } from "~~/store/dialogStore";

export default defineNuxtPlugin(nuxtApp => {

    const dialogStore = useDialogStore();

    watch(() => dialogStore.active, () => {

        if (dialogStore.active) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    })
});