<template>
    <nuxt-link :to="to" v-if="to">
        <picture>
            <source :srcset="scallableSources"/>
            <img class="__picture" :src="displayImage" :loading="loading">
        </picture>
    </nuxt-link>
    <picture v-else>
        <source :srcset="scallableSources"/>
        <img class="__picture" :src="displayImage" :loading="loading">
    </picture>
</template>

<script lang="ts" setup>

const props = defineProps<{
    src: string;
    eager?: boolean;
    to?: string | null
}>();


const format = inject('image-format');

let displayImage = '/' + props.src;
displayImage += '.x1.' + format;


const loading = props.eager ? 'eager' : 'lazy';

const scallableSources = computed(() => {
    const sources = [];

    for (let i = 1; i <= 3; i++) {
        let image = `/${props.src}.x${i}.${format} ${i}x`;
        sources.push(image);
    }

    return sources.join(', ');
});


</script>