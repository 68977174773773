import validate from "/builds/triyaru/triya-mobile-frontend-2/node_modules/nuxt/dist/pages/runtime/validate.js";
import short_45link_45global from "/builds/triyaru/triya-mobile-frontend-2/middleware/short-link.global.ts";
import manifest_45route_45rule from "/builds/triyaru/triya-mobile-frontend-2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  short_45link_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/triyaru/triya-mobile-frontend-2/middleware/auth.ts"),
  "not-auth": () => import("/builds/triyaru/triya-mobile-frontend-2/middleware/not_auth.ts")
}