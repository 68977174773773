<template>
    <nuxt-link ref="container" :to="offer.link" class="picture-container">
        <common-number-picture :src="offer.pictures[0]" size="m-offer" scallable :eager="eager" itemprop="image" v-if="!showSwiper"></common-number-picture>
        <swiper v-else pagination :modules="modules" loop nested>
            <swiper-slide  v-for="image of offer.pictures" :key="image">
                <common-number-picture :src="image" size="m-offer" scallable itemprop="image"></common-number-picture>
            </swiper-slide>
        </swiper>

        <img class="discount-icon" v-if="offer.price.icon" :src="offer.price.icon">
        <div class="offer-icons-container">
            <img src="~/assets/icons/catalog/new-icon.svg" alt="Новинка" v-if="offer.new">
            <img src="~/assets/icons/catalog/best-price.svg" class="new" alt="Лучшая цена" v-if="offer.bestPrice">
            <img src="~/assets/icons/catalog/glass.svg" class="new" alt="Стекло" v-if="offer.glass">
            <img src="~/assets/icons/catalog/express-delivery.svg" class="express" alt="Экспресс доставка" v-if="offer.hasExpressRemains">
        </div>
    </nuxt-link>
    
</template>

<script lang="ts" setup>
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { IOffer } from '~~/types/catalog';

const modules = [Pagination];

const showSwiper = ref(false);

const props = defineProps<{
    offer: IOffer;
    eager?: boolean;
}>();

const container = ref(null)

let observer: IntersectionObserver | null = null;

function onIntersect(entries: IntersectionObserverEntry[]) {
    if (entries.some((el) => el.isIntersecting)) {
        showSwiper.value = true;
        disconnect();
    }
}

onMounted(() => {

    if (props.offer.pictures.length < 2) {
        return;
    }

    observer = new IntersectionObserver(onIntersect, {
        threshold: 0.5
    });

    if (container.value) {
        // console.log(container.value);
        observer.observe(container.value!.$el);
    }
});

function disconnect() {
    observer?.disconnect();
    observer = null;
}

onUnmounted(() => {
    disconnect();
})


</script>

<style lang="scss" scoped>

@import '~/assets/css/colors.scss';


.picture-container {
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    line-height: 0;

    picture {
        height: 100%;
    }

    :deep(.__picture) {
        aspect-ratio: 250 / 200;
    }


    .discount-icon {
        position: absolute;
        right: 4px;
        top: 4px;
        width: 60px;
    }

    .offer-icons-container {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        z-index: 9;

        * {
            display: block;
            margin-top: 4px;
        }

        .express {
            height: 35px;
        }

        img {
            height: 18px;
        }
    }
}


:deep(.swiper-pagination) {
    position: absolute;
    z-index: 999;
    display: flex;
    gap: 4px;
    justify-content: center;
    // margin-top: 7px;
    left: 0;
    right: 0;
    bottom: 7px !important;
}

:deep(.swiper-pagination-bullet) {
    background: $color-white !important;
    transition: background .1s ease;
    opacity: 0.9;
    box-shadow: 0 0 1px rgba(0,0,0,.7);
    // border: 1px solid $color-grey-middle !important;
    width: 5px;
    
    height: 5px;
    padding: 0 !important;
    // margin: 2px 2px 2px !important;
    content: " ";
    border-radius: 50%;
    position: relative;
}
:deep(.swiper-pagination-bullet-active) {
    background: $color-red !important;
                box-shadow: 0 0 1px rgba($color-red,.7);
}


</style>