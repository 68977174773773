import { defineStore } from "pinia";
import type { IBottomFlash, IFlash, TFlashType } from "~~/types/flash";

export const useFlashStore = defineStore('flash', () => {

    const flash = ref<IFlash | null>(null);
    const bottomFlash = ref<IBottomFlash | null>(null);

    function setFlash(message: string, type: TFlashType, timeout?: number) {
        flash.value = {
            message,
            type,
            timeout
        };
    }

    function closeFlash() {
        flash.value = null;
    }

    function setBottomFlash(message: string, sub?: string, timeout?: number) {
        bottomFlash.value = {
            message,
            sub: sub ?? null,
            timeout
        };
    }

    function closeBottomFlash() {
        bottomFlash.value = null;
    }


    return { flash, bottomFlash, setFlash, closeFlash, setBottomFlash, closeBottomFlash }
});
