import { debounce } from "lodash-es";
import { defineStore } from "pinia";
import { useApi } from "~/lib";

type TFavoriteCallback = ((value: boolean) => void)[];

export const useFavoriteStore = defineStore('favorites', () => {

    const checkOffers = new Map<number, TFavoriteCallback>();
    const favoriteResult = new Map<number, boolean>();

    const count = ref(0);

    const api = useApi();

    const checker = debounce(async () => {
        const usedMap = new Map(checkOffers);
        checkOffers.clear();

        const response = await api.request('/rest/user/check-favorites', {
            ids: Array.from(usedMap.keys())
        });

        Object.entries(response).forEach(([id, value]) => {
            const callbacks = usedMap.get(Number(id));
            favoriteResult.set(Number(id), value as boolean);
            if (callbacks?.length) {
                callbacks.forEach((callback) => callback(value as boolean));
            }
        })

    }, 300);

    function checkFavorites(id: number, callback: (value: boolean) => void) {

        if (favoriteResult.has(id)) {
            callback(favoriteResult.get(id)!);
            return;
        }

        let targetContainer: TFavoriteCallback = [];

        if (checkOffers.has(id)) {
            targetContainer = checkOffers.get(id)!;
        }

        targetContainer.push(callback);

        checkOffers.set(id, targetContainer);
        checker();
    }

    async function toggle(id: number, value: boolean) {
        const oldValue = count.value;

        value ? count.value++ : count.value--;

        try {
            favoriteResult.set(id, value);
            await api.request('/rest/user/toggle-favorite-product', {
                id, value
            })
        } catch (err) {
            count.value = oldValue;
            favoriteResult.set(id, !value);
            throw err;
        }

    }

    return { count, checkFavorites, toggle};
});
