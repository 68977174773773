import { defineStore, skipHydrate } from "pinia";
import { useApi } from "~~/lib";

export const useRegionStore = defineStore('region', () => {

    const currentRegion = skipHydrate(ref(getRegionFromCookies()));
    const currentCity = skipHydrate(ref(getCityFromCookies()));

    const api = useApi();
    // const currentCity = skipHydrate(ref(getRegionFromCookies()));

    function getRegionFromCookies() {
        return useCookie('_r') ?? null;
    }
    function getCityFromCookies() {
        return useCookie('_c') ?? null;
    }

    async function setRegion(region: string, city: string) {
        await api.request('/rest/user/set-region', {
            region, city
        })

        currentRegion.value = region;
        currentCity.value = city;
    }

    return {currentRegion, currentCity, setRegion}
});

