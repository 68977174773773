<template>
    <div class="dialog-container">
        <div class="dialog-header">
            Выберите ваш город
            <div class="close-btn" @click="emits('close')">&times;</div>
        </div>
        <div class="search">
            <input :value="searchQuery" @input="event => searchQuery = event.target!.value"  placeholder="Поиск по городу, области" class="simple-input full-width">
        </div>
        <div class="city-list">
            <div class="city" v-for="city of displayCities" @click="setCity(city.region, city.city)">
                <div class="city-name">{{ city.name }}</div>
                <div class="city-region" v-if="city.regionName">{{ city.regionName }}</div>
                
                <div class="city-active" v-if="city.region === regionStore.currentRegion">
                    <img src="~/assets/icons/header/location_grey.svg" alt="Текущий регион">
                </div>
            </div>
        </div>
    </div>
</template>


<script setup lang="ts">
import { uniq } from 'lodash-es';
import { useApi } from '~~/lib';
import { useRegionStore } from '~~/store/regionStore';

const emits = defineEmits(['close']);

const api = useApi();
const regionStore = useRegionStore();

const data = await useLazyAsyncData(() => api.request('/rest/info/city-list'));

const searchQuery = ref('');

const availableCities = computed(() => {

    if (data.pending.value) {
        return [];
    }

    data.data.value.forEach((el) => {el.search = `${el.regionName} ${el.name}`.toLocaleLowerCase()});
    return data.data.value;
});

const displayCities = computed(() => {

    const query = searchQuery.value.trim().toLocaleLowerCase();

    if (query.length) {

        // хуйня, да
        return uniq([
            ...availableCities.value.filter((el) => el.name.toLowerCase().startsWith(query) && el.top).sort((a, b) => a.name.localeCompare(b.name)),
            ...availableCities.value.filter((el) => el.name.toLowerCase().startsWith(query)).sort((a, b) => a.name.localeCompare(b.name)),
            ...availableCities.value.filter((el) => el.regionName && el.regionName.toLowerCase().includes(query)).sort((a, b) => a.name.localeCompare(b.name)),
            ...availableCities.value.filter((el) => el.name.toLowerCase().includes(query)).sort((a, b) => a.name.localeCompare(b.name)),
            ...availableCities.value.filter((el) => el.regionName && el.regionName.toLowerCase().startsWith(query)).sort((a, b) => a.name.localeCompare(b.name)),
        ])
    }

    return displayDefaultList.value;
});

const displayDefaultList = computed(() => {
    let topCities = new Set(['532df4e6', '7e61938d']);
    if (regionStore.currentCity) {
        topCities.add(regionStore.currentCity);
    }
    

    return availableCities.value
        .filter((el) => el.top)
        .sort((a, b) => {

            if (a.city === regionStore.currentCity) {
                return -99999;
            } else if (b.city === regionStore.currentCity) {
                return 9999;
            }

            const aTop = topCities.has(a.city);
            const bTop = topCities.has(b.city);

            if (aTop === bTop) {
                return a.name.localeCompare(b.name);
            }

            if (aTop) {
                return -9999;
            }

            return 9999;
        });
});

let loading = false;

async function setCity(region: string, city: string) {

    if (loading) {
        return;
    }

    try {
        loading = true;
        await regionStore.setRegion(region, city);
        emits('close');
    } finally {
        loading = false;
    }
}


</script>

<style lang="scss" scoped src="./style.scss"></style>