import type { ICartSelectedService, ICartService } from "~~/types/services";

export function calculateServicePrice(selectedService: ICartSelectedService, services: ICartService[]): number {

    const service = services.find((el) => el.product === selectedService.key);

    if (!service) {
        return 0;
    }

    let resultPrice = 0;

    switch (service.calculate.type) {

        case 'static':
            resultPrice = service.calculate.value;
            break;
        case 'quantity':
            resultPrice = service.calculate.value * selectedService.quantity!;
            break;
        case 'static_quantity':
            resultPrice = service.calculate.minValue! + service.calculate.value * selectedService.quantity!;
            break;
        case 'static_percent':
            resultPrice = service.calculate.minValue! + (service.calculate.base / 100 * service.calculate.value);
            break;
        case 'percent':
            resultPrice = service.calculate.base / 100 * service.calculate.value;
            break;
        case 'percent_quantity':
            resultPrice = service.calculate.base / 100 * service.calculate.value * selectedService.quantity!;

            if (resultPrice < service.calculate.minValue! * selectedService.quantity!) {
                resultPrice = service.calculate.minValue! * selectedService.quantity!;
            }

            return Math.ceil(resultPrice);
        default:
            throw new Error('unknown price type');
    }

    return Math.ceil(Math.max(resultPrice, service.calculate.minValue ?? 0));
}