import { useApi } from "~/lib";
import { useFlashStore } from "~/store/flashStore";
import { useMainStore } from "~/store/mainState";

export default defineNuxtPlugin(nuxtApp => {

    if (!('geolocation' in navigator)) {
        return;
    }

    const mainStore = useMainStore();

    if (!mainStore.shouldAskGeo) {
        return;
    }

    navigator.geolocation.getCurrentPosition(async (position) => {
        if (position.coords) {

            try {
                const data = await useApi().request('/rest/geo/try', {
                    lon: position.coords.longitude,
                    lat: position.coords.latitude,
                });

                if (data) {
                    useFlashStore().setBottomFlash(`Ваш город определен как ${data.name}`, data.region, 4000);
                    mainStore.init();
                }

            } catch {}

            
        }
    })
});