<template>
    <form-button class="buy-btn xs" :class="color" :loading="loading" @click.capture.stop="buy">Купить</form-button>
</template>

<script lang="ts" setup>
import type { IOffer } from '~/types/catalog';
import { useCartStore } from '~~/store/cartStore';


const props = withDefaults(defineProps<{
    id: number,
    offer?: IOffer,
    color?: string,
    quantity?: boolean,
}>(), {
    color: 'red'
});


const cartStore = useCartStore();

const loading = ref(false);

async function buy() {

    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        await cartStore.buy(props.id)
    } catch {
        
    } finally {
        loading.value = false;
    }
}

</script>

<style lang="scss" scoped>
@import '~/assets/css/colors.scss';

.buy-btn {
    padding: 4px 10px !important;
    display: inline-flex;
    &.cart {
        background: var(--bg0);
        color: var(--t0);
    }

    &.red.cart {
        color: $color-red;
    }
}

</style>