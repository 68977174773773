<template>
    <div class="top-flash" :class="[flashStore.flash.type, {timeout: flashStore.flash.timeout}]" v-if="flashStore.flash" :style="{'--s': width}" @click="restart()">
        <div class="content">
            <div class="message"> {{ flashStore.flash.message }} </div>
        </div>

        <div class="close-btn" @click.capture.stop="flashStore.closeFlash()">&times;</div>
    </div>
</template>

<script lang="ts" setup>
import { useFlashStore } from '~~/store/flashStore';
import anime from 'animejs/lib/anime.es.js';


const flashStore = useFlashStore();

const width = ref('0%');

let animation: anime.AnimeInstance | null = null;

function stopAnimation() {
    if (animation) {
        animation.pause();
        animation = null;
    }
}

function setupTimeout() {
    animation = anime({
        targets: width,
        value: ['100%', '0%'],
        easing: 'linear',
        duration: flashStore.flash!.timeout!,
        complete: () => {
            flashStore.closeFlash();
        }
    });
}

function restart() {
    flashStore.flash?.timeout && animation?.restart();
}

watch(() => flashStore.flash, () => {
    stopAnimation();

    if (flashStore.flash?.timeout) {
        setupTimeout();
    }
})

</script>

<style lang="scss" src="./style.scss" scoped>
</style>
