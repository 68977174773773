export const metrikaId = 12075184;

export default defineNuxtPlugin({
    name: 'metrika',
    parallel: true,
    async setup(nuxtApp) {
        window.dataLayer = window.dataLayer || [];

        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        var z = null;m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(metrikaId, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true,
            // defer: true,
            ecommerce: true
        });
    
        const router = useRouter();

        router.afterEach((to, from) => {
            ym(metrikaId, 'hit', to.fullPath, {
                options: {referer: from.fullPath}
            });
        })
    }
})
