import { defineStore, skipHydrate } from "pinia";
import { calculateServicePrice } from "~~/lib/service";
import type { ICartCheckoutService, ICartSelectedService, ICartServiceInfo } from "~~/types/services";

interface IStoredServices {
    [key: string]: {
        key: string;
        quantity?: number;
    }
}


export const useServiceStore = defineStore('services', () => {

    const data: ICartServiceInfo = reactive({
        cityList: [],
        current: '',
        services: []
    });

    const selectedServices: Map<string, ICartSelectedService> = reactive(new Map());

    function getStoredServices() {

        selectedServices.clear();

        try {
            const saved: IStoredServices = JSON.parse(sessionStorage.getItem('selected-services')!);


            Object.keys(saved).forEach((groupKey) => {
                const value = saved[groupKey];

                const suitableService = data.services.find((el) => el.group === groupKey && el.product == value.key);

                if (suitableService) {
                    if (suitableService.withQuantity && !((value.quantity ?? 0) > 0)) {
                        value.quantity = 1;
                    }

                    selectedServices.set(groupKey, value);
                }
            });

        } catch {
            selectedServices.clear();
            
        }

        createDefaultServiceSelection();

        removeMissingServices();

        updateServicesPrice();
    }

    function createDefaultServiceSelection() {
        data.services.forEach((service) => {
            if (service.default && !selectedServices.has(service.group)) {
                selectedServices.set(service.group, {
                    key: service.product,
                    quantity: 1
                })
            }
        })
    }

    function saveSelectedServices() {

        const save: IStoredServices = {};

        selectedServices.forEach((value, key) => {
            save[key] = {
                key: value.key,
                quantity: value.quantity
            };
        });

        try {
            if (typeof sessionStorage !== 'undefined') {
                sessionStorage.setItem('selected-services', JSON.stringify(save));
            }
        } catch {}
    }

    function setServices(value: ICartServiceInfo) {
        data.current = value.current;
        data.services = value.services;

        data.cityList = value.cityList

        // value.cityList.forEach((el) => {
        //     data.cityList.set(el.code, el.name);
        // })

        const quantityServices = new Set(['quantity', 'percent_quantity', 'static_quantity']);

        data.services.forEach((service) => {
            if (service.group !== 'delivery') {
                service.group = service.type;
            }

            service.withQuantity = quantityServices.has(service.calculate.type);
        })

        removeMissingServices();

        updateServicesPrice();
    }

    function selectService(key: string) {
        const service = data.services.find((el) => el.product === key);

        if (!service) {
            return;
        }

        if (selectedServices.get(service.group)?.key === key) {
            if (service.group !== 'delivery') {
                selectedServices.delete(service.group);
            }
        } else {
            selectedServices.set(service.group, {
                key, quantity: 1
            })
        }

        updateServicesPrice();

        saveSelectedServices();
    }

    function updateService(key: string, quantity: number) {
        const service = data.services.find((el) => el.product === key);

        if (!service) {
            return;
        }

        const selectedService = selectedServices.get(service.group);

        if (!selectedService || selectedService.key !== key) {
            return;
        }

        selectedService.quantity = quantity;

        updateServicesPrice();

        saveSelectedServices();
    }

    function removeMissingServices() {

        let removed = false;

        selectedServices.forEach((value, groupKey) => {
            const serviceExists = data.services.some((el) => el.group === groupKey && el.product == value.key);

            if (!serviceExists) {
                removed = true;
                selectedServices.delete(groupKey);
            }
        });


        if (removed) {
            createDefaultServiceSelection();
            updateServicesPrice();
            saveSelectedServices();
        }
    }

    function updateServicesPrice() {

        selectedServices.forEach((value, key) => {
            value.price = calculateServicePrice(value, data.services);
        })
    }

    const deliveryServices = computed(() =>  data.services.filter((el) => el.group === 'delivery'));
    const regularServices = computed(() => data.services.filter((el) => el.group !== 'delivery'));

    const cityMap = computed(() => {
        return new Map(data.cityList.map((el) => [el.code, el.name]));
    })
    
    const currentCity = computed(() => {
        return cityMap.value.get(data.current)!;
    })

    const checkoutServices = computed(() => {
        const services: ICartCheckoutService[] = [];
        selectedServices.forEach((service) => {
            services.push({
                code: service.key,
                quantity: Math.min(service.quantity ?? 1, 1),
            })
        });

        return services;
    })

    return {deliveryServices, regularServices, currentCity, setServices, selectedServices, selectService, checkoutServices, updateService, getStoredServices, data}
});
